import React, { useState, useEffect } from "react";
import { Button, Card, Row, Col, Spinner, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import "./Products.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

function Products() {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [dataPerPage] = useState(10);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchProducts(currentPage);
  }, [currentPage]);

  const fetchProducts = async (pageNo) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:3000/bhakshanangal/productlist",
        { page_no: pageNo },
        { headers: { "Content-Type": "application/json" } }
      );
      if (response.data.result) {
        setProducts(response.data.data);
        setTotalDataCount(response.data.total_data_count);
      } else {
        setError("Failed to fetch products.");
      }
    } catch (err) {
      setError("An error occurred while fetching data.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmDelete = (product) => {
    setSelectedProduct(product);
    setShowDeleteModal(true);
  };

  const handleDeleteProduct = async () => {
    if (!selectedProduct) return;
    try {
      setIsLoading(true);
      const userId = localStorage.getItem("user_id");
      const userApiKey = localStorage.getItem("user_api_key");

      if (!userId || !userApiKey) {
        throw new Error("User ID or API Key is missing from local storage.");
      }

      const response = await axios.post(
        "https://lunarsenterprises.com:3000/bhakshanangal/delete-product",
        { product_id: selectedProduct.product_id },
        {
          headers: {
            "Content-Type": "application/json",
            api_key: userApiKey,
            user_id: userId,
          },
        }
      );

      if (response.data.result) {
        fetchProducts(currentPage);
      }
    } catch (err) {
      console.error("Delete Product Error:", err);
    } finally {
      setIsLoading(false);
      setShowDeleteModal(false);
    }
  };

  return (
    <div className="container mt-4">
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button className="add-product-btn" onClick={() => navigate("/AddProduct")}>
          ADD PRODUCT
        </Button>
      </div>
      {isLoading && <Spinner animation="border" />} 
      {error && <p className="text-danger">{error}</p>}
      <Row xs={1} sm={2} md={3} lg={4} className="g-4 mt-4">
        {products.map((product) => (
          <Col md={4} className="mb-4" key={product.product_id}>
            <Card className="card-size-adjust">
              <div className="card-image-container">
                <Link to={`/EditProduct/${product.product_id}`}>
                  <FontAwesomeIcon className="icon-left" icon={faPenToSquare} />
                </Link>
                <img
                  src={`https://lunarsenterprises.com:3000/${product.image_file}`}
                  alt={product.product_name}
                  className="card-img-top"
                />
                <FontAwesomeIcon
                  className="icon-right"
                  icon={faTrash}
                  onClick={() => handleConfirmDelete(product)}
                />
              </div>
              <Card.Body>
                <Card.Title>{product.product_name}</Card.Title>
                <Card.Text>₹{product.price}</Card.Text>
                <Card.Text>{product.quantity} {product.product_unit}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      <div className="pagination-container mt-4">
        <Button
          className="button-number"
          variant="primary"
          disabled={currentPage === 1}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          Previous
        </Button>
        <span className="mx-3">
          Page {currentPage} of {Math.ceil(totalDataCount / dataPerPage)}
        </span>
        <Button
          variant="primary"
          className="button-number"
          disabled={currentPage === Math.ceil(totalDataCount / dataPerPage)}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          Next
        </Button>
      </div>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete <strong>{selectedProduct?.product_name}</strong>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteProduct}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Products;
