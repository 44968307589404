import React, { useState, useEffect } from "react";
import "./AddProduct.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const AddProduct = () => {
  const navigate = useNavigate();
  const userId = localStorage.getItem("user_id");
  const userApiKey = localStorage.getItem("user_api_key");

  const [productData, setProductData] = useState({
    product_name: "",
    product_description: "",
    priceinINR: "",
    quantity: "",
    stock: "", 
    shipping: 1,
    cod: 1,
    refund: 1,
    free_delivery: 1,
    category: "",
    product_unit: "",
    image: null,
  });

  const [categories, setCategories] = useState([]);
  const [exist, setExist] = useState("");

  
  useEffect(() => {
    axios
      .post("https://lunarsenterprises.com:3000/bhakshanangal/categorylist")
      .then((response) => {
        if (response.data && response.data.result) {
          setCategories(response.data.list);
        } else {
          console.error("Failed to fetch categories");
        }
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductData({
      ...productData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    setProductData({
      ...productData,
      [e.target.name]: e.target.checked ? 1 : 0,
    });
  };

  const handleImageChange = (e) => {
    setProductData({
      ...productData,
      image: e.target.files[0],
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("product_name", productData.product_name);
    formData.append("product_description", productData.product_description);
    formData.append("priceinINR", productData.priceinINR);
    formData.append("quantity", productData.quantity);
    formData.append("stock", productData.stock);
    formData.append("shipping", productData.shipping);
    formData.append("cod", productData.cod);
    formData.append("refund", productData.refund);
    formData.append("free_delivery", productData.free_delivery);
    formData.append("category", productData.category);
    formData.append("unit", productData.product_unit);
  
    if (productData.image) {
      formData.append("image", productData.image);
    }
  
    // Send API request
    axios({
      url: "https://lunarsenterprises.com:3000/bhakshanangal/addproduct",
      method: "POST",
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        user_id: userId,
        api_key: userApiKey,
      },
    })
      .then((response) => {
        if (response.data.result === true) {
          toast.success("Product added successfully!"); // Updated toast message
          navigate("/products"); // Redirect to products page after success
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error adding product:", error);
        toast.error("Failed to add product.");
      });
  };
  

  return (
    <div className="add-product-form-container">
      <ToastContainer />
      <h2>Add Product</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="product_name" className="add-product-form-label">
            Product Name:
          </label>
          <input
            type="text"
            id="product_name"
            name="product_name"
            value={productData.product_name}
            onChange={handleChange}
            className="add-product-input"
          />
        </div>
        <div>
          <label
            htmlFor="product_description"
            className="add-product-form-label"
          >
            Product Description:
          </label>
          <textarea
            id="product_description"
            name="product_description"
            value={productData.product_description}
            onChange={handleChange}
            className="add-product-textarea"
          />
        </div>
        <div>
          <label htmlFor="priceinINR" className="add-product-form-label">
            Price in INR:
          </label>
          <input
            type="number"
            id="priceinINR"
            name="priceinINR"
            value={productData.priceinINR}
            onChange={handleChange}
            className="add-product-input"
          />
        </div>
        <div>
          <label htmlFor="quantity" className="add-product-form-label">
            Quantity:
          </label>
          <input
            type="number"
            id="quantity"
            name="quantity"
            value={productData.quantity}
            onChange={handleChange}
            className="add-product-input"
          />
        </div>
        <div>
          <label htmlFor="stock" className="add-product-form-label">
            {" "}
            {/* Stock field */}
            Stock:
          </label>
          <input
            type="number"
            id="stock"
            name="stock"
            value={productData.stock}
            onChange={handleChange}
            className="add-product-input"
          />
        </div>
        <div>
          <label htmlFor="shipping" className="add-product-form-label">
            Shipping:
          </label>
          <input
            type="checkbox"
            id="shipping"
            name="shipping"
            checked={productData?.shipping === 1}
            onChange={handleCheckboxChange}
            className="add-product-checkbox"
          />
        </div>
        <div>
          <label htmlFor="cod" className="add-product-form-label">
            COD Available:
          </label>
          <input
            type="checkbox"
            id="cod"
            name="cod"
            checked={productData?.cod === 1}
            onChange={handleCheckboxChange}
            className="add-product-checkbox"
          />
        </div>
        <div>
          <label htmlFor="refund" className="add-product-form-label">
            Refund Policy:
          </label>
          <input
            type="checkbox"
            id="refund"
            name="refund"
            checked={productData?.refund === 1}
            onChange={handleCheckboxChange}
            className="add-product-checkbox"
          />
        </div>
        <div>
          <label htmlFor="free_delivery" className="add-product-form-label">
            Free Delivery:
          </label>
          <input
            type="checkbox"
            id="free_delivery"
            name="free_delivery"
            checked={productData?.free_delivery === 1}
            onChange={handleCheckboxChange}
            className="add-product-checkbox"
          />
        </div>
        <div>
  <label htmlFor="category" className="add-product-form-label">
    Category:
  </label>
  <select
    id="category"
    name="category"
    value={productData.category}
    onChange={handleChange}
    className="add-product-input"
  >
    <option value="">Select Category</option>
    {categories && categories.length > 0 ? (
      categories.map((category) => (
        <option key={category.category_id} value={category?.category_id}>
          {category.category_name || category.category}
        </option>
      ))
    ) : (
      <option disabled>No categories available</option>
    )}
  </select>
</div>

        <div>
          <label htmlFor="product_unit" className="add-product-form-label">
            Unit:
          </label>
          <select
            id="product_unit"
            name="product_unit"
            value={productData?.product_unit}
            onChange={handleChange}
            className="add-product-input"
          >
            <option value="">Select Unit</option>
            <option value="gm">Gram</option>
            <option value="kg">Kilogram</option>
            <option value="ltr">Liter</option>
          </select>
        </div>
        <div>
          <label htmlFor="image" className="add-product-form-label">
            Product Image:
          </label>
          <input
            type="file"
            id="image"
            name="image"
            onChange={handleImageChange}
            className="add-product-input"
          />
          {exist && (
            <div>
              <img
                src={`https://lunarsenterprises.com:3000/${exist}`}
                alt="Product"
                className="existing-image"
              />
            </div>
          )}
        </div>
        <div>
          <button type="submit" className="add-product-button w-100">
            Add Product
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddProduct;
